const Status = (props) => {
  let backgroundColor;
  let icon;
  switch (props.status) {
    case "Operational":
      backgroundColor = `flex flex-col justify-between text-white  uppercase font-bold 
      bg-gradient-to-br from-green-400 to-green-500`;
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      );
      break;
    case "Partial":
      backgroundColor = `flex flex-col justify-between text-white  uppercase font-bold 
      bg-gradient-to-br from-yellow-400 to-yellow-500`;
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      );
      break;
    case "In Review":
      backgroundColor = `flex flex-col justify-between text-white  uppercase font-bold 
      bg-gradient-to-br from-yellow-400 to-yellow-500`;
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      );
      break;
    case "Performance":
      backgroundColor = `flex flex-col justify-between text-white  uppercase font-bold 
      bg-gradient-to-br from-red-400 to-red-500`;
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      );
      break;
    default:
      backgroundColor = `flex flex-col justify-between text-white  uppercase font-bold 
      bg-gradient-to-br from-gray-400 to-gray-500`;
  }
  return (
    <div className={backgroundColor}>
      <div className="flex p-2 text-white justify-between gap-2 md:gap-0">
        <h4 className="p-0 m-0 font-bold text-xs uppercase text-gray-50 md:text-center">
          Last updated
        </h4>
        <h5 className="p-0 m-0 text-xs text-gray-100">
          {new Date(props.lastUpdated).toLocaleString()}
        </h5>
        <div className="flex text-xs items-center">{props.status}</div>
      </div>
    </div>
  );
};
export default Status;
