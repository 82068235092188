import { useState } from "react";
import JobFormModal from "./JobFormModal";
import JobOffer from "./JobOffer";

export default function JobGrid(props) {
  let [isOpen, setIsOpen] = useState(false);
  let [modalType, setModalType] = useState(false);

  function openModal(type) {
    setIsOpen(true);
    setModalType(type);
    console.log(type, "modal is open.");
  }

  function closeModal() {
    setIsOpen(false);
    console.log("Modal closed");
  }

  return (
    <div className="grid md:grid-cols-2 gap-4">
      {props.jobs.map((job) =>
        job.map((jobData) => (
          <JobOffer
            key={jobData.id}
            data={jobData}
            deleteJobHandler={props.deleteJobHandler}
            updateJobHandler={props.updateJobHandler}
          />
        ))
      )}
      <div
        onClick={() => openModal("Create")}
        className="cursor-pointer flex-col gap-8 items-center justify-center text-gray-300 hover:text-gray-400 mt-6 pt-10 opacity-50 hover:opacity-100 transition-all ease-in duration-300 flex border p-6 border-opacity-25 rounded-lg shadow-sm"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex h-16 w-16"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M12 4v16m8-8H4"
          />
        </svg>

        <h3 className="text-xl uppercase font-thin transition duration-500 ease-in-out hover:opacity-100 m-0">
          Create new job offer
        </h3>
        {isOpen && (
          <JobFormModal
            modalType={modalType}
            sections={props.sections}
            isOpen={isOpen}
            closeModal={closeModal}
            addJobHandler={props.addJobHandler}
          />
        )}
      </div>
    </div>
  );
}
