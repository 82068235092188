import { useState } from "react";
import EditModal from "./JobFormModal";
import DeleteModal from "./UI/DeleteJobModal";

export default function JobOffer({ data, deleteJobHandler, updateJobHandler }) {
  // JobOffer props example:
  //   description: "We are looking for a creative, multidisciplinary and open minded collaborator to join our team. You will be responsible for growing revenue through digital media through designing and executing marketing campaigns, managing RRSS and online directories and forums."
  //   end_date: "30-9-2021"
  //   id: 5
  //   id_section: 8
  //   place: "Bogota, Colombia"
  //   section: "Marketing"
  //   start_date: "8-9-2021"
  //   status: 1
  //   title: "Especialista en marketing digital"
  //   url: "https://www.linkedin.com/jobs/view/2706592244/?refId=%2F74Qt307qNOa%2B56vEB%2F5%2Bw%3D%3D"
  //   worktime: "Full-time"
  let [isOpen, setIsOpen] = useState(false);
  let [modalType, setModalType] = useState(false);

  function openModal(type) {
    setIsOpen(true);
    setModalType(type);
    console.log(type, "modal is open.");
  }

  function closeModal() {
    setIsOpen(false);
    console.log("Modal closed");
  }

  return (
    <div className="mt-6 pt-10 transition-all ease-in duration-300 flex border p-6 border-opacity-25 rounded-lg shadow-sm">
      {isOpen && modalType === "Edit" ? (
        <EditModal
          modalType={modalType}
          job={data}
          isOpen={isOpen}
          closeModal={closeModal}
          updateJobHandler={updateJobHandler}
        />
      ) : (
        <DeleteModal
          modalType={modalType}
          job={data}
          isOpen={isOpen}
          closeModal={closeModal}
          deleteJobHandler={deleteJobHandler}
        />
      )}
      <div key={data.title} className="flex flex-col flex-grow">
        <div className="flex justify-between text-sm text-gray-500">
          <h4>
            {data.start_date.substring(0, 10)} /{" "}
            {data.end_date.substring(0, 10)}
          </h4>
          <div className="flex flex-row gap-4 ">
            <button onClick={() => openModal("Edit")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-600 transition-all ease-in duration-150 hover:text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </button>
            <button onClick={() => openModal("Delete")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-red-600 transition-all ease-in duration-150 hover:text-red-400 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <h2 className="text-xl font-semibold text-gray-900">
            {data.title.toUpperCase()}
          </h2>
          <h3 className="font-base font-semibold text-gray-700">
            {data.worktime.toUpperCase()}
          </h3>
          <p className="mt-2 text-base text-gray-500 w-10/12">
            {data.description}
          </p>
        </div>
        <button className="flex flex-grow justify-end flex-col text-lg font-semibold text-gray-800">
          <a
            href={data.url}
            className="flex text-base font-semibold text-blue-600 hover:text-blue-500"
            target="_blank"
            rel="noreferrer"
          >
            Check LinkedinURL
          </a>
        </button>
      </div>
    </div>
  );
}
