import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import ServiceTable from "./ServiceTable";

import axios from "axios";
import { Select } from "antd";

const BillingApp = (props) => {
  document.title = "CX Internal - Billing";
  document.body.style.backgroundColor = "#FAFAFA";
  // console.log("Keycloack", props.keycloak);
  // console.log("Billing Props Keycloack:", props.keycloak.token);

  const [countries, setCountries] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sites, setSites] = useState(null);

  const [services, setServices] = useState(null);
  const [summary, setSummary] = useState(null);

  const { Option } = Select;

  const BACKEND_URL = "https://api.ipxon.net/internal/reports";

  useEffect(() => {
    const fetchCountryList = async () => {
      const headers = {
        Authorization: `Bearer ${props.keycloak.token}`,
      };
      await axios
        .get(BACKEND_URL + "/config/countries", { headers })
        .then((result) => {
          setCountries(result.data);
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            //TODO TOKEN REFRESH HERE
            props.keycloak.init({ onLoad: "login-required" });
            //
          }
        });
    };
    fetchCountryList();
  }, []);

  function handleCountryChange(value) {
    if (value.length === 0) {
      setSites(null);
      setSelectedCountry(null);
      setServices(null);
      setSummary(null);
    } else {
      setSelectedCountry(value);
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${props.keycloak.token}`,
      };
      axios
        .all([
          axios.get(BACKEND_URL + `/config/sites?countries=${value}`, {
            headers,
          }),
          axios.get(BACKEND_URL + `/services?countries=${value}`, { headers }),
        ])
        .then(
          axios.spread((sites, services) => {
            setServices(services.data.services);
            setSummary(services.data.summary);
            setSites(sites.data);
            setLoading(false);
          })
        );
    }
  }

  function handleSiteChange(value) {
    if (value.length === 0 && !selectedCountry) {
      //console.log("No country or site selected. Clearing state");
      setSelectedCountry(null);
      setServices(null);
      setSummary(null);
      setSites(null);
    }
    if (value.length === 0 && selectedCountry) {
      const headers = {
        Authorization: `Bearer ${props.keycloak.token}`,
      };
      axios
        .get(BACKEND_URL + `/services?countries=${selectedCountry}`, {
          headers,
        })
        .then(function (response) {
          setServices(response.data.services);
          setSummary(response.data.summary);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const headers = {
        Authorization: `Bearer ${props.keycloak.token}`,
      };
      axios
        .get(BACKEND_URL + `/services?sites=${value}`, { headers })
        .then(function (response) {
          setServices(response.data.services);
          setSummary(response.data.summary);
        })
        .catch(function (error) {
          if (error.message.includes("401")) {
            //TODO TOKEN REFRESH HERE
            props.keycloak.init({ onLoad: "login-required" });
            //
          }
        });
    }
  }

  return (
    <>
      <div className="flex flex-start bg-gray-50">
        <div className="flex flex-grow flex-col">
          <div>
            <div className="grid grid-cols-2 gap-8 py-4 px-2">
              <div className="col-span-1">
                {countries ? (
                  <Select
                    mode="multiple"
                    allowClear
                    className="min-w-full"
                    placeholder="Select country"
                    onChange={handleCountryChange}
                  >
                    {countries.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    mode="multiple"
                    allowClear
                    className="min-w-full"
                    placeholder="No country data available"
                    disabled
                  />
                )}
              </div>
              <div className="col-span-1">
                {selectedCountry && sites ? (
                  <Select
                    mode="multiple"
                    allowClear
                    className="min-w-full"
                    placeholder="Select site/s"
                    onChange={handleSiteChange}
                  >
                    {sites.map((site) => (
                      <Option key={site.id} value={site.id}>
                        {site.location}
                      </Option>
                    ))}
                  </Select>
                ) : isLoading ? (
                  <Select
                    mode="multiple"
                    allowClear
                    className="min-w-full"
                    placeholder="Loading sites.."
                    disabled
                    loading
                  />
                ) : (
                  <Select
                    mode="multiple"
                    allowClear
                    className="min-w-full"
                    placeholder="No country selected"
                    disabled
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {services && selectedCountry && (
        <ServiceTable
          summary={summary}
          services={services}
          selectedCountry={selectedCountry}
          keycloak={props.keycloak}
        />
      )}
    </>
  );
};

export default BillingApp;
