import { useState } from "react";
import CreateSectionForm from "./CreateSectionForm";
import JobGrid from "./JobGrid";
import ListBox from "./ListBox";
import SectionFormModal from "./SectionFormModal";
import DeleteSectionModal from "./UI/DeleteSectionModal";

export default function JobTable(props) {
  let [isOpen, setIsOpen] = useState(false);
  let [modalType, setModalType] = useState(false);

  function openModal(type) {
    setIsOpen(true);
    setModalType(type);
    console.log(type, "modal is open.");
  }

  function closeModal() {
    setIsOpen(false);
    console.log("Modal closed");
  }
  return (
    <div className="flex flex-col w-full">
      <div className="p-2">Select section:</div>
      <div className="flex gap-8">
        <ListBox
          list={props.sections}
          setSectionHandler={props.setSectionHandler}
          loadJobOffersHandler={props.loadJobOffersHandler}
        />
        <div className="flex items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => openModal("Create")}
            className="h-6 w-6 text-gray-600 transition-all ease-in duration-150 hover:text-red-400 cursor-pointer"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6 text-red-600 transition-all ease-in duration-150 hover:text-red-400 cursor-pointer"
            onClick={() => openModal("Delete")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>
      <JobGrid
        sections={props.sections}
        jobs={props.jobs}
        addJobHandler={props.addJobHandler}
        deleteJobHandler={props.deleteJobHandler}
        updateJobHandler={props.updateJobHandler}
      />
      {isOpen && modalType === "Delete" ? (
        <DeleteSectionModal
          isOpen={isOpen}
          modalType={modalType}
          closeModal={closeModal}
          sectionId={props.selectedSection}
          deleteSectionHandler={props.deleteSectionHandler}
        />
      ) : (
        <SectionFormModal
          isOpen={isOpen}
          modalType={modalType}
          closeModal={closeModal}
          addSectionHandler={props.addSectionHandler}
        />
      )}
    </div>
  );
}
