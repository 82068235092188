import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import Flag from "react-world-flags";

export default function Modal(props) {
  // console.log("Modal props:", props);
  let closeButtonRef = useRef(null);

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={props.closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-5/6 max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl ">
                <Dialog.Title
                  as="h3"
                  className="text-2xl md:text-4xl flex md:font-thin uppercase leading-6 text-gray-900 pt-4 m-0 gap-2 items-center"
                >
                  <span>{props.city}</span>
                  <span className="w-6 flex self-start mt-1 md:mt-2 shadow-md">
                    <Flag code={props.country} />
                  </span>
                </Dialog.Title>
                <Dialog.Description
                  as="h4"
                  className="md:text-lg font-extralight m-0 leading-6 mb-3 md:mb-6"
                >
                  <span className="text-lg md:text-xl">
                    {props.title} ({props.subtitle}):
                  </span>
                </Dialog.Description>
                {/* Modal content */}
                {props.children}
                {/* Modal close button */}
                <div className="flex justify-end mt-2">
                  <button
                    type="button"
                    className="inline-flex justify-end px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent  hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={props.closeModal}
                    ref={closeButtonRef}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
