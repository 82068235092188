function AppIcon(props) {
  document.title = `CX Internal - ${props.navTitle}`;
  return (
    <div>
      {/* SVG ICON */}
      <div className="flex justify-center">{props.icon}</div>
      {/* APP NAME - TITLE */}
      <h3
        className="text-xl m-0 font-light"
        style={{
          fontColor: "inherit",
        }}
      >
        {props.title}
      </h3>
      {/* APP DESCRIPTION - SUBTITLE */}
      <p className="m-0 self-end">{props.description}</p>
      {props.comingSoon && <p>Coming soon!</p>}
    </div>
  );
}

export default AppIcon;
