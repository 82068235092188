const CountryTitle = ({ code }) => {
  let title = "";

  switch (code) {
    case "UY":
      title = "Uruguay";
      break;
    case "PE":
      title = "Peru";
      break;
    case "AR":
      title = "Argentina";
      break;
    case "EC":
      title = "Ecuador";
      break;
    case "CO":
      title = "Colombia";
      break;
    case "PY":
      title = "Paraguay";
      break;
    case "GT":
      title = "Guatemala";
      break;
    case "CL":
      title = "Chile";
      break;
    case "BR":
      title = "Brazil";
      break;
    case "CA":
      title = "Canada";
      break;
    case "US":
      title = "United States";
      break;
    case "MX":
      title = "Mexico";
      break;
    case "HN":
      title = "Honduras";
      break;
    case "CR":
      title = "Costa Rica";
      break;
    case "PA":
      title = "Panama";
      break;
    case "SV":
      title = "El Salvador";
      break;
    default:
  }

  return <span>{title.toUpperCase()}</span>;
};

export default CountryTitle;
