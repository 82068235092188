import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Logout extends Component {
  logout() {
    this.props.keycloak.logout();
  }

  render() {
    return (
      <button onClick={() => this.logout()} className="w-full flex flex-start">
        Logout
      </button>
    );
  }
}
export default withRouter(Logout);
