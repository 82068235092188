import Status from "./Status";
import Title from "./Title";
import { useState } from "react";
import Modal from "../../UI/MessageModal";

const Service = (props) => {
  // console.log("SERVICE PROPS:", props);
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className="relative flex flex-col w-full">
      <div
        className="bg-gray-50 filter drop-shadow-md cursor-pointer"
        onClick={() => openModal()}
      >
        <Title
          title={props.data.item_service_name}
          subtitle={props.data.service_name}
          status={props.data.service_status_name}
        />
        <Status
          status={props.data.service_status_name}
          lastUpdated={props.data.date}
        />

        <Modal
          isOpen={isOpen}
          closeModal={closeModal}
          title={props.data.item_service_name}
          subtitle={props.data.service_name}
          description={props.data.incident_description}
          lastUpdated={props.data.date}
          city={props.city}
          country={props.country}
        >
          <div>
            {props.data.history.length != 0 ? (
              <div className="max-h-96 overflow-auto">
                <table className="w-full">
                  <tr className="uppercase">
                    <th className="w-1/3">Date</th>
                    <th className="w-2/3 px-2">Update</th>
                  </tr>
                  {props.data.history.map((update) => (
                    <tr
                      key={update.id}
                      className="transform even:bg-gray-100 text-xs my-2 p-2"
                    >
                      <td className="w-1/3 align-text-top">
                        {update.date}{" "}
                        <span className="invisible md:visible">
                          - {update.time}
                        </span>
                      </td>
                      <td className="w-2/3 align-text-top px-2 py-2">
                        {update.incident_description}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ) : (
              <h4 className="mb-4 font-normal">
                No updates logged for this service.
              </h4>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default Service;
