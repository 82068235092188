import { useState, useEffect } from "react";
import axios from "axios";
import JobTable from "./JobTable";
import cxLogo from "../assets/svg/cx-iso-color.svg";

export default function Jobs(props) {
  document.title = `CX Internal - Jobs`;
  document.body.style.backgroundColor = "#FAFAFA";

  const [jobs, setJobs] = useState(null);
  const [sections, setSections] = useState(null);
  const [selectedSection, setSelectedSection] = useState();

  function setSectionHandler(sectionId) {
    console.log("Setting section: ", sectionId);
    setSelectedSection(sectionId);
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    axios
      .get(
        `https://api.ipxon.net/internal/jobs/opportunities?seccionId=` +
          sectionId,
        { headers }
      )
      .then((response) => {
        console.log(
          "Response from https://api.ipxon.net/internal/jobs/opportunities:",
          response.data.opportunities
        );
        setJobs(Object.values(response.data.opportunities));
      });
  }

  function deleteJobHandler(job) {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    fetch(
      "https://api.ipxon.net/internal/jobs/opportunities/" + job.id,
      { headers },
      {
        method: "DELETE",
      }
    ).then(
      () => {
        refreshJobList();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function deleteSectionHandler(sectionId) {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    fetch(
      "https://api.ipxon.net/internal/jobs/sections/" + sectionId,
      { headers },
      {
        method: "DELETE",
      }
    ).then(
      () => {
        refreshJobList();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function addSectionHandler(section) {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    axios
      .put("https://api.ipxon.net/internal/jobs/sections", section, { headers })
      .then(
        () => {
          refreshJobList();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function addJobHandler(job) {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    axios
      .put("https://api.ipxon.net/internal/jobs/opportunities", job, {
        headers,
      })
      .then(
        () => {
          refreshJobList();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function updateJobHandler(job) {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    axios
      .post(
        "https://api.ipxon.net/internal/jobs/opportunities/" + job.id,
        job,
        { headers }
      )
      .then(
        () => {
          loadJobOffersHandler();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function refreshJobList() {
    if (selectedSection && selectedSection != "All sections") {
      const headers = {
        Authorization: `Bearer ${props.keycloak.token}`,
      };
      axios
        .get(
          `https://api.ipxon.net/internal/jobs/opportunities?seccionId=` +
            selectedSection,
          { headers }
        )
        .then((response) => {
          setJobs(Object.values(response.data.opportunities));
        });
    } else {
      const headers = {
        Authorization: `Bearer ${props.keycloak.token}`,
      };
      axios
        .get(`https://api.ipxon.net/internal/jobs/opportunities`, { headers })
        .then((response) => {
          setJobs(Object.values(response.data.opportunities));
        });
    }
  }

  function loadJobOffersHandler() {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    axios
      .get(`https://api.ipxon.net/internal/jobs/opportunities`, { headers })
      .then((response) => {
        setJobs(Object.values(response.data.opportunities));
      });
  }

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${props.keycloak.token}`,
    };
    axios
      .get(`https://api.ipxon.net/internal/jobs/opportunities`, { headers })
      .then((response) => {
        setJobs(Object.values(response.data.opportunities));
      });
    axios
      .get(`https://api.ipxon.net/internal/jobs/sections`, { headers })
      .then((response) => {
        setSections(response.data.sections);
      });
  }, []);

  return (
    <div>
      {jobs && sections ? (
        <div className="flex justify-center bg-white border border-opacity-75 hover:text-black transition duration-300 ease-in-out rounded-md p-8">
          <JobTable
            jobs={jobs}
            sections={sections}
            selectedSection={selectedSection}
            setSectionHandler={setSectionHandler}
            addJobHandler={addJobHandler}
            deleteJobHandler={deleteJobHandler}
            addSectionHandler={addSectionHandler}
            deleteSectionHandler={deleteSectionHandler}
            loadJobOffersHandler={loadJobOffersHandler}
            updateJobHandler={updateJobHandler}
          />
        </div>
      ) : (
        <div className=" flex flex-col gap-8 h-screen w-screen items-center justify-center opacity-50">
          <img
            className="animate-spin flex h-16 w-16"
            src={cxLogo}
            alt="Conexum Logo"
          />
          <div className="font-thin">Processing.. </div>
        </div>
      )}
    </div>
  );
}
