import Service from "./Service/Service";

const Site = (props) => {
  // console.log("SITE PROPS:", props);
  return (
    <div className="px-4">
      <h2 className="text-xl font-thin">
        {props.data.city_name.toUpperCase()}
      </h2>
      <ol className="flex flex-col pb-8 gap-4">
        {props.data.data_service.map((service) => (
          <li key={service.service_monitor_id} className="w-full flex">
            <Service
              country={props.country}
              city={props.data.city_name}
              data={service}
            />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Site;
