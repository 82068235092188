import { useState, useEffect } from "react";
import { Button } from "antd";
import {
  CaretRightOutlined,
  BorderOutlined,
  PauseOutlined,
  PoweroffOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { LoadingButton } from "./LoadingButton";

const ENDPOINT = "https://auth-proxmox.ipxon.host/";
const STOP_ENDPOINT = ENDPOINT + "api/public/cloud/node/qemu/status/stop";
const START_ENDPOINT = ENDPOINT + "api/public/cloud/node/qemu/status/start";
const STATUS_ENDPOINT = ENDPOINT + "api/public/cloud/node/qemu/status";
const PAUSE_ENDPOINT = ENDPOINT + "api/public/cloud/node/qemu/status/suspend";
const SHUTDOWN_ENDPOINT =
  ENDPOINT + "api/public/cloud/node/qemu/status/shutdown";
const RESUME_ENDPOINT = ENDPOINT + "api/public/cloud/node/qemu/status/resume";

const ExpandedService = (props) => {
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [starting, setStarting] = useState(false);
  const [resuming, setResuming] = useState(false);
  const [pausing, setPausing] = useState(false);
  const [stopping, setStopping] = useState(false);
  const [shuttingDown, setShuttingDown] = useState(false);

  //Executes ONCE when App loads.
  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", props.sid);
    axios
      .post(STATUS_ENDPOINT, formdata, { headers })
      .then((response) => {
        if (response.status === 204) {
          setErrorMessage("No data found for this service".toUpperCase());
        }
        setStatus(response.data.message);
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          //TODO TOKEN REFRESH HERE
          props.keycloak.init({ onLoad: "login-required" });
          //
        }
      })
      .finally(() => {
        setStarting(false);
        setLoading(false);
      });
  }, []);

  const stopHandler = (serviceId) => {
    setStopping(true);
    // console.log("Stopping:", serviceId);
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", serviceId);
    axios
      .post(STOP_ENDPOINT, formdata, { headers })
      .then((response) => {
        // console.log(response.data.message, serviceId);
        updateServiceStatus(serviceId);
      })
      .catch(function (error) {
        if (error.message.includes("401")) {
          props.keycloak.init({ onLoad: "login-required" });
        }
      })
      .finally(() => {
        setStarting(false);
        setLoading(false);
      });
  };

  const startHandler = (serviceId) => {
    // console.log("Starting:", serviceId);
    setStarting(true);
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", serviceId);
    axios
      .post(START_ENDPOINT, formdata, { headers })
      .then((response) => {
        // console.log(response.data.message, serviceId);
        updateServiceStatus(serviceId);
      })
      .catch(function (error) {
        if (error.message.includes("401")) {
          props.keycloak.init({ onLoad: "login-required" });
        }
      })
      .finally(() => {
        setStarting(false);
        setLoading(false);
      });
  };

  const pauseHandler = (serviceId) => {
    setPausing(true);
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", serviceId);

    axios
      .post(PAUSE_ENDPOINT, formdata, { headers })
      .then((response) => {
        // console.log(response.data.message, serviceId);
        updateServiceStatus(serviceId);
      })
      .catch(function (error) {
        if (error.message.includes("401")) {
          props.keycloak.init({ onLoad: "login-required" });
        }
      })
      .finally(() => {
        setStarting(false);
        setLoading(false);
      });
  };

  const resumeHandler = (serviceId) => {
    setResuming(true);
    // console.log("Resuming:", serviceId);
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", serviceId);

    axios
      .post(RESUME_ENDPOINT, formdata, { headers })
      .then((response) => {
        // console.log(response.data.message, serviceId);
        updateServiceStatus(serviceId);
      })
      .catch(function (error) {
        if (error.message.includes("401")) {
          props.keycloak.init({ onLoad: "login-required" });
        }
      })
      .finally(() => {
        setStarting(false);
        setLoading(false);
      });
  };

  const shutdownHandler = (serviceId) => {
    setShuttingDown(true);
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", serviceId);

    axios
      .post(SHUTDOWN_ENDPOINT, formdata, { headers })
      .then((response) => {
        // console.log(response.data.message, serviceId);
        // console.log(response.status);
        updateServiceStatus(serviceId);
        if (response.data.message === "busy") {
          setErrorMessage("Unable to shutdown. The resource is currently busy");
        }
      })
      .catch(function (error) {
        if (error.message.includes("401")) {
          props.keycloak.init({ onLoad: "login-required" });
        }
      })
      .finally(() => {
        setStarting(false);
        setLoading(false);
      });
  };

  const updateServiceStatus = (serviceId) => {
    const headers = {
      Authorization: `Bearer ${props.token}`,
    };
    let formdata = new FormData();
    formdata.append("sid", serviceId);
    axios
      .post(STATUS_ENDPOINT, formdata, { headers })
      .then((response) => {
        // console.log("Updating SID: ", serviceId);
        // console.log(response);
        setStatus(response.data.message);
        setStarting(false);
        setResuming(false);
        setShuttingDown(false);
        setPausing(false);
        setStopping(false);
        setErrorMessage(null);
      })
      .catch(function (error) {
        setErrorMessage(error);
        console.log(error);
      });
  };

  function createButtons(status, serviceId) {
    switch (status) {
      case "running":
        return (
          <>
            <Button type="primary" disabled>
              <div className="flex justify-center gap-2">
                <span className="flex self-center">
                  <CaretRightOutlined />
                </span>
                <span>Start</span>
              </div>
            </Button>
            {pausing ? (
              <Button type="primary" className="pointer-events-none" disabled>
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <LoadingOutlined />
                  </span>
                  <span>Pausing...</span>
                </div>
              </Button>
            ) : (
              <Button type="primary" onClick={() => pauseHandler(serviceId)}>
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <PauseOutlined />
                  </span>
                  <span>Pause</span>
                </div>
              </Button>
            )}
            <Button type="primary" onClick={() => stopHandler(serviceId)}>
              {stopping ? (
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <LoadingOutlined />
                  </span>
                  <span>Stopping...</span>
                </div>
              ) : (
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <BorderOutlined />
                  </span>
                  <span>Stop</span>
                </div>
              )}
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => shutdownHandler(serviceId)}
            >
              {shuttingDown ? (
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <LoadingOutlined />
                  </span>
                  <span>Shutting down...</span>
                </div>
              ) : (
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <PoweroffOutlined />
                  </span>
                  <span>Shutdown</span>
                </div>
              )}
            </Button>
          </>
        );
      case "paused":
        return (
          <>
            {resuming ? (
              <LoadingButton text="Resuming" />
            ) : (
              <Button type="primary" onClick={() => resumeHandler(serviceId)}>
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <CaretRightOutlined />
                  </span>
                  <span>Resume</span>
                </div>
              </Button>
            )}
            <Button type="primary" disabled>
              <PauseOutlined />
              <span className="flex justify-center">Pause</span>
            </Button>
            {stopping ? (
              <LoadingButton text="Stopping" />
            ) : (
              <Button type="primary" onClick={() => stopHandler(serviceId)}>
                <div className="flex justify-center gap-2">
                  <span className="flex self-center">
                    <BorderOutlined />
                  </span>
                  <span>Stop</span>
                </div>
              </Button>
            )}
            <Button type="primary" disabled>
              <PoweroffOutlined />
              <span className="flex justify-center">Shutdown</span>
            </Button>
          </>
        );
      case "stopped":
        return (
          <>
            {starting ? (
              <LoadingButton text="Starting" />
            ) : (
              <Button type="primary" onClick={() => startHandler(serviceId)}>
                <div className="flex justify-between gap-2">
                  <span className="flex self-center">
                    <CaretRightOutlined />
                  </span>
                  <span>Start</span>
                </div>
              </Button>
            )}
            <Button type="primary" disabled>
              <PauseOutlined />
              <span className="flex justify-center">Pause</span>
            </Button>
            <Button type="primary" disabled>
              <BorderOutlined />
              <span className="flex justify-center">Stop</span>
            </Button>
            <Button type="primary" disabled>
              <PoweroffOutlined />
              <span className="flex justify-center">Shutdown</span>
            </Button>
          </>
        );
      case "shutdown":
        return (
          <>
            <Button type="primary" disabled>
              <div className="flex justify-between gap-2">
                <span className="flex self-center">
                  <CaretRightOutlined />
                </span>
                <span>Start</span>
              </div>
            </Button>
            <Button type="primary" disabled>
              <PauseOutlined />
              <span className="flex justify-center">Pause</span>
            </Button>
            <Button type="primary" disabled>
              <BorderOutlined />
              <span className="flex justify-center">Stop</span>
            </Button>
            <Button danger onClick={() => shutdownHandler(serviceId)}>
              <PoweroffOutlined />
              <span className="flex justify-center">Shutdown</span>
            </Button>
          </>
        );
      default:
        return null;
    }
  }
  return (
    <>
      {status && (
        <div className="flex justify-evenly items-center px-8">
          <span className="font-normal">
            Service status:{" "}
            <span className="font-bold">{status.toUpperCase()}</span>
          </span>
          {createButtons(status, props.sid)}
        </div>
      )}
      {loading && (
        <div className="flex gap-6 justify-center">
          <p className="m-0">Loading service data</p>
          <LoadingOutlined />
        </div>
      )}
      {errorMessage && (
        <div className="flex justify-center gap-2 pt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <div className="text-gray-800 uppercase">{errorMessage}</div>
        </div>
      )}
    </>
  );
};

export default ExpandedService;
