import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import Flag from "react-world-flags";
import CountryTitle from "../CountryTitle";

export default function Select(props) {
  const [selected, setSelected] = useState(props.countries[0].name);
  const [selectedId, setSelectedId] = useState(props.countries[0].id);

  function handleSetSelected(country) {
    // console.log(country);
    setSelected(country.name);
    setSelectedId(country.id);
    props.handleCountryChange(country.id);
  }

  return (
    <div className="md:hidden mx-8 z-50">
      <Listbox value={selected} onChange={handleSetSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative flex gap-2 w-full py-2 pl-3 pr-10 text-left bg-white z-40 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="text-2xl uppercase md:text-5xl font-extralight md:font-thin m-0 flex gap-2">
              {selected}
            </span>
            <span className="w-6 md:w-8 flex self-start mt-2 shadow-md">
              <Flag code={selectedId} />
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {props.countries.map((country) => (
                <Listbox.Option
                  key={country.id}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-4 pr-4`
                  }
                  value={country}
                >
                  {(selected) => (
                    <span
                      className={`${
                        selected ? "font-medium" : "font-normal"
                      } flex gap-2`}
                    >
                      <span className="text-2xl md:text-5xl font-extralight md:font-thin m-0 flex gap-2">
                        <CountryTitle code={country.id} />
                      </span>

                      <span className="w-6 md:w-8 flex self-start mt-2 shadow-md">
                        <Flag code={country.id} />
                      </span>
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
