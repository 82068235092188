import { useState, useRef } from "react";
import { Table, Space, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Flag from "react-world-flags";
import Highlighter from "react-highlight-words";

import ClientName from "./Service/ClientName";
import LinkedId from "./Service/LinkedId";
import ServiceTag from "./Service/ServiceTag";
import ExpandedService from "./Service/ExpandedService";
import ServiceStatusTag from "./Service/ServiceStatusTag";

const ServiceTable = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  function getColumnSearchProps(dataIndex, placeholder) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search by ` + placeholder}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#FFD100", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  const columns = [
    {
      title: "Company",
      dataIndex: ["companyname"],
      ...getColumnSearchProps("companyname", "company"),
      render: (text, data) => <ClientName name={data} key={data.clientid} />,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "VPS",
          value: "vps",
        },
        {
          text: "Dedicated",
          value: "dedicated",
        },
        {
          text: "Collocation",
          value: "collocation",
        },
        {
          text: "Proxy",
          value: "proxy",
        },
        {
          text: "Hosting",
          value: "hosting",
        },
        {
          text: "Custom",
          value: "custom",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (type) => <ServiceTag serviceType={type} key={type} />,
    },
    {
      title: "Plan",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", "plan"),
    },
    {
      title: "Service",
      dataIndex: "serviceid",
      key: "serviceid",
      ...getColumnSearchProps("serviceid", "service ID"),
      sorter: {
        compare: (a, b) => a.serviceid - b.serviceid,
      },
      render: (text, data) => (
        <LinkedId
          link={`https://customers.ipxon.com/admin/clientsservices.php?id=`}
          id={data.serviceid}
          key={data.serviceid}
        />
      ),
    },
    {
      title: "Client",
      dataIndex: "clientid",
      key: "clientid",
      ...getColumnSearchProps("clientid", "client ID"),
      render: (text, data) => (
        <LinkedId
          link={`https://customers.ipxon.com/admin/clientssummary.php?userid=`}
          id={data.clientid}
          key={data.clientid}
        />
      ),
    },
    {
      title: "Order",
      dataIndex: "orderid",
      key: "orderid",
      ...getColumnSearchProps("orderid", "order ID"),
      sorter: {
        compare: (a, b) => a.orderid - b.orderid,
      },
      render: (text, data) => (
        <LinkedId
          link={`https://customers.ipxon.com/admin/orders.php?action=view&id=`}
          id={data.orderid}
          key={data.orderid}
        />
      ),
    },
    {
      title: "Due date",
      dataIndex: "nextduedate",
      key: "nextduedate",
      render: (text, data) => <span>{data.nextduedate}</span>,
    },
    {
      title: "IP",
      dataIndex: "dedicatedip",
      key: "dedicatedip",
      ...getColumnSearchProps("dedicatedip", "IP"),
      render: (text, data) => <span>{data.dedicatedip}</span>,
    },

    {
      title: "Method",
      dataIndex: "paymentmethod",
      key: "paymentmethod",

      filters: [
        {
          text: "Stripe",
          value: "stripe",
        },
        {
          text: "Paypal",
          value: "paypal",
        },
        {
          text: "Blockonomics",
          value: "blockonomics",
        },
        {
          text: "Banktransfer",
          value: "banktransfer",
        },
        {
          text: "Banco Davivienda",
          value: "daviviendcotransfer",
        },
        {
          text: "Boatransfer",
          value: "boatransfer",
        },
      ],
      onFilter: (value, record) => record.paymentmethod.indexOf(value) === 0,
      render: (text, data) => <span>{data.paymentmethod}</span>,
    },
    {
      title: "Status",
      dataIndex: "domainstatus",
      key: "domainstatus",
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Suspended",
          value: "Suspended",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
      onFilter: (value, record) => record.domainstatus.indexOf(value) === 0,
      render: (text, data) => (
        <ServiceStatusTag serviceStatus={data.domainstatus} />
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (country) => (
        <Flag className="h-10 w-10" margin="0" code={country} key={country} />
      ),
    },
  ];
  return (
    <>
      {props.summary && (
        <div className="flex justify-center gap-8 p-2 bg-gray-50">
          <h2>
            <span className="font-thin text-2xl">Total services: </span>
            <span className="font-semibold text-xl">
              {props.summary.quantity}
            </span>
          </h2>
          <h2>
            <span className="font-thin text-2xl">Total: </span>
            <span className="font-semibold text-xl">
              ${Math.round(props.summary.amount)}
            </span>
          </h2>
        </div>
      )}
      <Table
        rowClassName={(record, index) => `
        ${record.domainstatus === "Suspended" && "opacity-50"} 
        `}
        dataSource={props.services}
        columns={columns}
        rowKey={(service) => service.serviceid}
        expandable={{
          expandedRowRender: (service) =>
            service.type === "vps" ? (
              <ExpandedService
                sid={service.serviceid}
                token={props.keycloak.token}
                keycloak={props.keycloak}
              />
            ) : (
              <span className="flex justify-center">
                ONLY AVAILABLE FOR VPS SERVICES
              </span>
            ),
        }}
      />
    </>
  );
};

export default ServiceTable;
