import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BillingSvg, JobsSvg, NetworkSvg } from "../assets/svgIcons";
import AppIcon from "./AppIcon";
import HomeTitle from "./HomeTitle";

function Home(props) {
  document.title = "CX Internal - Home";

  let [userName, setUserName] = useState(null);

  useEffect(() => {
    props.keycloak.loadUserInfo().then((userInfo) => {
      setUserName(userInfo.given_name);
    });
  }, [props.keycloak]);

  return (
    <>
      {userName && (
        <div className="p-8 md:px-8 md:py-16 container mx-auto">
          <HomeTitle
            userName={userName}
            greeting="What amazing thing do you want to do today?"
          />

          <div className="flex flex-col md:flex-row gap-4 text-center font-thin">
            <Link
              to="/billing"
              className="border border-gray-400 text-black hover:text-black opacity-50 hover:opacity-100 transition duration-300 ease-in-out rounded-md md:w-1/3 p-8"
            >
              <AppIcon
                navTitle="Billing"
                icon={<BillingSvg />}
                title="CX Billing"
                description="Check, suspend, pause, stop and shut down services."
              />
            </Link>
            <Link
              to="/jobs"
              className="border border-gray-400 text-black hover:text-black opacity-50 hover:opacity-100 transition duration-300 ease-in-out rounded-md md:w-1/3 p-8"
            >
              <AppIcon
                navTitle="Jobs"
                icon={<JobsSvg />}
                title="CX Jobs"
                description="Upload amazing job offers to the CX website"
                comingSoon={false}
              />
            </Link>
            <Link
              to="/network-status"
              className="flex flex-col border border-gray-400 text-black hover:text-black opacity-50 hover:opacity-100 transition duration-300 ease-in-out rounded-md md:w-1/3 p-8"
            >
              <AppIcon
                navTitle="Network Status"
                icon={<NetworkSvg />}
                title="CX Network Status"
                description="Check the realtime status of the CX PoPs"
                comingSoon={false}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
