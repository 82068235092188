import { Tag } from "antd";

const ServiceTag = ({ serviceType }) => {
  let tag;
  switch (serviceType) {
    case "vps":
      tag = <Tag color="green">{serviceType.toUpperCase()}</Tag>;
      break;
    case "dedicated":
      tag = <Tag color="blue">{serviceType.toUpperCase()}</Tag>;
      break;
    case "proxy":
      tag = <Tag color="gold">{serviceType.toUpperCase()}</Tag>;
      break;
    case "hosting":
      tag = <Tag color="orange">{serviceType.toUpperCase()}</Tag>;
      break;
    case "custom":
      tag = <Tag color="purple">{serviceType.toUpperCase()}</Tag>;
      break;
    case "collocation":
      tag = <Tag color="volcano">{serviceType.toUpperCase()}</Tag>;
      break;
    default:
      tag = <span>NO TYPE</span>;
      break;
  }
  return tag;
};

export default ServiceTag;

/* 
<Tag color="magenta">magenta</Tag>
<Tag color="red">red</Tag>
<Tag color="volcano">volcano</Tag>
<Tag color="orange">orange</Tag>
<Tag color="gold">gold</Tag>
<Tag color="lime">lime</Tag>
<Tag color="green">green</Tag>
<Tag color="cyan">cyan</Tag>
<Tag color="blue">blue</Tag>
<Tag color="geekblue">geekblue</Tag>
<Tag color="purple">purple</Tag>
*/
