import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function JobForm({ selectedJob, closeModal, updateJobHandler }) {
  const schema = yup
    .object({
      start: yup.string().required(),
      end: yup.string().required(),
      section: yup.number().required(),
      title: yup.string().required(),
      description: yup.string().required(),
      place: yup.string().required(),
      worktime: yup.string().required(),
      url: yup.string().required(),
    })
    .required();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      id: selectedJob.id,
      section: selectedJob.id_section,
      title: selectedJob.title,
      description: selectedJob.description,
      place: selectedJob.place,
      worktime: selectedJob.worktime,
      url: selectedJob.url,
      start: selectedJob.start_date,
      end: selectedJob.end_date,
      status: selectedJob.status,
    },
    resolver: yupResolver(schema),
  });

  // function updateJob(job) {
  //   axios
  //     .post("https://api.ipxon.net/internal/jobs/opportunities/" + job.id, job)
  //     .then(
  //       () => {
  //         closeModal();
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  return (
    <form
      onSubmit={handleSubmit((data) => {
        // console.log("FORM SUBMITED FOR UPDATING: ", data);
        updateJobHandler(data);
        closeModal();
      })}
      className="flex flex-col flex-grow gap-4 text-lg font-thin"
    >
      <div className="flex justify-between">
        <div>
          <label
            htmlFor="start"
            className="block text-sm font-medium text-gray-700"
          >
            Start date:
          </label>
          <div className="mt-1">
            <input
              {...register("start")}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="end"
            className="block text-sm font-medium text-gray-700"
          >
            End date:
          </label>
          <div className="mt-1">
            <input
              {...register("end")}
              className="fappearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Title:
        </label>
        <div className="mt-1">
          <input
            {...register("title")}
            className="font-bold appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description:
        </label>
        <div className="mt-1">
          <textarea
            rows={8}
            {...register("description")}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="place"
          className="block text-sm font-medium text-gray-700"
        >
          Location:
        </label>
        <div className="mt-1">
          <input
            {...register("place")}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="url"
          className="block text-sm font-medium text-gray-700"
        >
          Linkedin URL:
        </label>
        <div className="mt-1">
          <textarea
            rows={2}
            {...register("url")}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="worktime"
          className="block text-sm font-medium text-gray-700"
        >
          Worktime:
        </label>
        <div className="mt-1">
          <select
            {...register("worktime")}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="part-time">
              Part time (Less than 30 hours per week)
            </option>
            <option value="Full-time">
              Full time (Between 30-40 hours a week)
            </option>
          </select>
        </div>
      </div>
      <div className="flex gap-4">
        <label
          htmlFor="status"
          className="block text-sm font-medium text-gray-700"
        >
          Active offer? :
        </label>
        <input
          className="self-center"
          type="checkbox"
          {...register("status")}
        />
      </div>

      <button
        type="button"
        type="submit"
        className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-200 bg-blue-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      >
        Submit
      </button>
    </form>
  );
}
