import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// JobOffer props example:
// description: "We are looking for a creative, multidisciplinary and open minded collaborator to join our team. You will be responsible for growing revenue through digital media through designing and executing marketing campaigns, managing RRSS and online directories and forums."
// end_date: "30-9-2021"
// id: 5
// id_section: 8
// place: "Bogota, Colombia"
// section: "Marketing"
// start_date: "8-9-2021"
// status: 1
// title: "Especialista en marketing digital"
// url: "https://www.linkedin.com/jobs/view/2706592244/?refId=%2F74Qt307qNOa%2B56vEB%2F5%2Bw%3D%3D"
// worktime: "Full-time"

//ENDPOINT EXPECTS:
// "section": 0,
// "title": "string",
// "description": "string",
// "place": "string",
// "worktime": "string",
// "url": "string",
// "start": "string",
// "end": "string",
// "status": true

export default function CreateJobForm({ closeModal, sections, addJobHandler }) {
  //console.log("Create Job Form handler:", addJobHandler);
  //console.log("Sections prop:", sections);
  const schema = yup
    .object({
      start: yup.string().required(),
      end: yup.string().required(),
      section: yup.number().required(),
      title: yup.string().required(),
      description: yup.string().required(),
      place: yup.string().required(),
      worktime: yup.string().required(),
      url: yup.string().required(),
    })
    .required();

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      section: "",
      title: "",
      description: "",
      place: "",
      worktime: "",
      url: "",
      start: "",
      end: "",
      status: true,
    },
    resolver: yupResolver(schema),
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        addJobHandler(data);
        closeModal();
      })}
      className="flex flex-col flex-grow gap-4 text-lg font-thin"
    >
      {/* <div className="flex justify-between">
        <div>
          <label
            htmlFor="start"
            className="block text-sm font-medium text-gray-700"
          >
            Start date:
          </label>
          <div
            className="mt-1"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <Controller
              control={control}
              name="start"
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select start date"
                  onChange={(date) => field.onChange(date)}
                  dateFormat="dd/MM/yyyy"
                  selected={field.value}
                />
              )}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="end"
            className="block text-sm font-medium text-gray-700"
          >
            End date:
          </label>
          <div
            className="mt-1"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <Controller
              control={control}
              name="end"
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select end date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  showMonthDropdown
                />
              )}
            />
          </div>
        </div>
      </div> */}
      <div className="flex justify-between">
        <div>
          <label
            htmlFor="start"
            className="block text-sm font-medium text-gray-700"
          >
            Start date:
          </label>
          <div className="mt-1">
            <input
              {...register("start")}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="end"
            className="block text-sm font-medium text-gray-700"
          >
            End date:
          </label>
          <div className="mt-1">
            <input
              {...register("end")}
              className="fappearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Section:
        </label>
        <div className="mt-1">
          <select
            {...register("section")}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {sections.map((section) => {
              return (
                <option value={section.id} key={section.id}>
                  {section.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Title:
        </label>
        <div className="mt-1">
          <input
            {...register("title")}
            className="font-bold appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description:
        </label>
        <div className="mt-1">
          <textarea
            rows={8}
            {...register("description")}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="place"
          className="block text-sm font-medium text-gray-700"
        >
          Location:
        </label>
        <div className="mt-1">
          <input
            {...register("place")}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="url"
          className="block text-sm font-medium text-gray-700"
        >
          Linkedin URL:
        </label>
        <div className="mt-1">
          <textarea
            rows={2}
            {...register("url")}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="worktime"
          className="block text-sm font-medium text-gray-700"
        >
          Worktime:
        </label>
        <div className="mt-1">
          <select
            {...register("worktime")}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="part-time">
              Part time (Less than 30 hours per week)
            </option>
            <option value="Full-time">
              Full time (Between 30-40 hours a week)
            </option>
          </select>
        </div>
      </div>
      <button
        type="button"
        type="submit"
        className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-200 bg-blue-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      >
        Submit
      </button>
    </form>
  );
}
