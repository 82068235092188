import { useRef, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

function Map(props) {
  const chart = useRef(null);

  useEffect(() => {
    //Create Map instance
    let map = am4core.create("chartdiv", am4maps.MapChart);
    //Set map defitnition (Quality)
    map.geodata = am4geodata_worldLow;
    //Setmap projection (Shape)
    map.projection = new am4maps.projections.Miller();

    // Map areas (countries, states, etc.) are represented by MapPolygonSeries objects.
    let polygonSeries = new am4maps.MapPolygonSeries();
    //Load geographical data geographical data defining the curvature of the map areas
    polygonSeries.useGeodata = true;
    map.series.push(polygonSeries);
    //Exclude Antarctica and include CX Points of Prescense
    polygonSeries.exclude = ["AQ"];
    polygonSeries.include = [
      "UY",
      "PE",
      "AR",
      "EC",
      "CO",
      "PA",
      "MX",
      "US",
      "CL",
      "CA",
      "BR",
      "PY",
      "GT",
      "HN",
      "SV",
      "CR",
      "NI",
      "HN",
      "BO",
      "VE",
      "GY",
      "SR",
      "GF",
    ];
    //Load countries (Props)
    // console.log("Map props:", props.countries);
    polygonSeries.data = props.countries;

    //Configure base MapPolygons (COUNTRIES)
    let countries = polygonSeries.mapPolygons.template;
    countries.tooltipText = "{name} ({id})";
    countries.propertyFields.fill = "colorMap";

    //Country active/hover states for countries
    let hoverCountry = countries.states.create("hover");
    hoverCountry.properties.opacity = 0.5;
    var activeCountry = countries.states.create("active");
    activeCountry.properties.opacity = 0.5;

    //Onclick for countries (polygons)
    var currentActive;
    const excludedCountries = ["VE", "GY", "SR", "GF", "BO", "NI"];
    countries.events.on("hit", function (ev) {
      let countryCode = ev.target.dataItem.dataContext.id;
      if (excludedCountries.includes(countryCode)) {
        // console.log("No data for:", countryCode);
      } else {
        if (currentActive) {
          currentActive.isActive = false;
        }
        currentActive = ev.target;
        currentActive.isActive = true;
        let countryToZoom = polygonSeries.getPolygonById(countryCode);
        // console.log("countryToZoom", countryToZoom);
        map.zoomToMapObject(countryToZoom);
        props.handleCountryChange(countryCode);
      }
    });

    //
    //
    //Configure base MapImageSeries (SITES)
    var sites = map.series.push(new am4maps.MapImageSeries());
    //Load sites from props
    sites.data = props.sites;
    //Bind props properties with marker properties
    sites.mapImages.template.propertyFields.longitude = "longitude";
    sites.mapImages.template.propertyFields.latitude = "latitude";
    sites.mapImages.template.tooltipText = "{name}";
    sites.mapImages.template.propertyFields.fill = "color";
    //
    //
    //

    //Create site template
    let site = sites.mapImages.template;
    //Create marker icon (Sprite) for each site
    let markerIcon = site.createChild(am4core.Circle);
    markerIcon.radius = 8;
    markerIcon.fill = am4core.color("#B27799");
    markerIcon.stroke = am4core.color("#FFFFFF");
    markerIcon.strokeWidth = 2;
    markerIcon.nonScaling = true;
    markerIcon.tooltipText = "{title}";
    markerIcon.propertyFields.fill = "color";

    // markerIcon.path =
    //   "M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z";
    // markerIcon.width = 25;
    // markerIcon.height = 25;
    // markerIcon.nonScaling = true;
    // markerIcon.horizontalCenter = "middle";
    // markerIcon.verticalCenter = "bottom";
    // markerIcon.propertyFields.fill = "color";
    // markerIcon.stroke = "1";
    //Onclick for sites (TODO MODAL HERE)
    site.events.on("hit", function (ev) {
      // console.log(ev.target.dataItem.dataContext);
    });

    // Zoom controls
    map.zoomControl = new am4maps.ZoomControl();
    // Home button
    let homeButton = new am4core.Button();
    homeButton.events.on("hit", function () {
      map.goHome();
      props.clearSelectedCountry();
      currentActive
        ? (currentActive.isActive = false)
        : (currentActive = undefined);
    });
    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.marginBottom = 2.5;
    homeButton.parent = map.zoomControl;
    homeButton.insertBefore(map.zoomControl.plusButton);
    map.zoomControl.align = "right";
    map.zoomControl.valign = "bottom";
    map.zoomControl.marginBottom = 75;

    chart.current = map;
    return () => {
      map.dispose();
    };
  }, []);

  return <div id="chartdiv" className="w-full h-full z-0 hidden sm:flex" />;
}

export default Map;
