import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const LoadingButton = ({ text }) => {
  //status: Starting... || Pausing... || Stopping... || Shuting down...
  return (
    <Button type="primary" disabled>
      <div className="flex justify-center gap-2">
        <span className="flex self-center">
          <LoadingOutlined />
        </span>
        <span>{text}...</span>
      </div>
    </Button>
  );
};

// export const BaseButton = ({ text, icon }) => {
//   //status: Starting... || Pausing... || Stopping... || Shuting down...
//   return (
//     <>
//       {pausing ? (
//         <LoadingButton text="Pausing" />
//       ) : (
//         <Button type="primary" onClick={() => pause(serviceId)}>
//           <div className="flex justify-center gap-2">
//             <span className="flex self-center">
//               <PauseOutlined />
//             </span>
//             <span>Pause</span>
//           </div>
//         </Button>
//       )}
//     </>
//   );
// };
