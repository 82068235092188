import { useState, useEffect } from "react";
import axios from "axios";
import Site from "./Site";
import CountryTitle from "../CountryTitle";
import Flag from "react-world-flags";
import Map from "./Map";
import Dropdown from "../UI/Select";
import Select from "../UI/Select";

function NetworkStatusApp() {
  // let mapEndpoint = "https://test.ipxon.host/api/v1/public/service/status";
  let mapEndpoint =
    "https://api.ipxon.net/public/monitoring/api/v1/service/status";
  // let adminToken =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiMTc0MDQyZDEyYTRjYjdlMDQxMjNjZTZhNjViZDY5NzhlNWEwZDYwNzg1NDhlZTkxZDZjN2EzZGQ3Mzg5YmYyNGM2YWE1YzEyNjQwYTM2ZjIiLCJpYXQiOjE2MjUyNTIyODcsIm5iZiI6MTYyNTI1MjI4NywiZXhwIjoxNjU2Nzg4Mjg3LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.NgfmwXnHaraDsDPVjHdAioBxVS7q1iM9CmuRxteus4y6iuphilklbFg6WnR067-1__Mx391DchlcOl8M38L-x5j5eIZodD7bPekcviyjByy8yLpdQ2hbj4AZWzPF4RGZ2sNyomWzrTSaUH0Q8FT6bCfsHviDgmBqKyZpULQyhWA8Y3XPQvxkfZSEV7I6mqlpSugK2Q1dowuvlIR5QGLaKXzVXgsLyBDFi_OfWyPDRZeGO5j94SPCxYaP2C2LFiRPZKJAeL6hixaYSFGxmOkYpJ3YmZl9EhTze_V8Kyz2z7Z99PUDdrZ9af0m5UDXn0m6l05DtByKmHkHDS5t4EcXYVSImFDSUrbvfD9kPXGC3ux89R-tdRXGmeUjrq-QVfES-jqVO_3HYccsRw_1ZkWI7RTiqtk1MdC21mhd1WCdoPBbUlnGVzTK6yjI3MHVcDgHGzUnl5fZJ41kjpW0SgbNBYLwjvpdzbbvta2VQGMkbF9ZPzBJuTj_Gb7SCJV6D3C-ZyX44RNdP54awoAi_pVYYxTwx7FGPS1rwnHCBNfzLtrIGdC5_xsSDaCa1DynOXOp4aSfPeULBbTNn7AjB3baMPVv5HP1-DIh5kc_OadamozEIq8F7nhUMUduG0gaXFE_0lslK3RQYVJPMHlkBNP7mt_IsECGdWAXJEXYJe0_sFA";

  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountrySites, setSelectedCountrySites] = useState(null);
  const [sites, setSites] = useState(null);

  useEffect(() => {
    const fetchSites = async () => {
      axios
        .get(mapEndpoint + "/all/map")
        .then((result) => {
          setSites(result.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchSites();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      axios
        .get(mapEndpoint)
        .then((result) => {
          setCountries(result.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchCountries();
  }, [selectedCountry]);

  function handleCountryChange(props) {
    axios
      .get(mapEndpoint + "/" + props)
      .then(function (response) {
        setSelectedCountry(props);
        setSelectedCountrySites(response.data.data[0].arrayItem);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function clearSelectedCountry() {
    setSelectedCountrySites(null);
    setSelectedCountry(null);
  }

  return (
    <div className="h-screen">
      <div className="flex flex-col h-full">
        <div className="block mt-8 relative z-50 sm:hidden">
          {countries && (
            <Select
              countries={countries}
              handleCountryChange={handleCountryChange}
            />
          )}
        </div>
        <ul className="md:absolute md:w-1/2 lg:w-1/3 w-6/6 flex flex-col z-20">
          {selectedCountry ? (
            <div className="bg-white md:bg-gray-200 md:bg-opacity-75 border border-white py-8 md:p-8 flex flex-col gap-4 overflow-auto">
              <h2 className="text-2xl hidden md:text-4xl px-2 font-extralight md:font-thin m-0 md:flex gap-2">
                <CountryTitle code={selectedCountry} />
                <span className="w-4 md:w-8 flex self-start mt-2 shadow-md">
                  <Flag code={selectedCountry} />
                </span>
              </h2>

              <div className="flex-col h-full md:max-h-96 overflow-auto">
                {selectedCountrySites &&
                  selectedCountrySites.map((site) => (
                    <li key={site.city_name}>
                      <Site country={selectedCountry} data={site} />
                    </li>
                  ))}
              </div>
            </div>
          ) : (
            countries && (
              <h2 className="text-5xl p-8 font-thin m-0 flex">
                PLEASE SELECT A COUNTRY
              </h2>
            )
          )}
        </ul>

        {sites && countries && (
          <Map
            countries={countries}
            sites={sites}
            selectedCountry={selectedCountry}
            handleCountryChange={handleCountryChange}
            clearSelectedCountry={clearSelectedCountry}
          />
        )}
      </div>
    </div>
  );
}
export default NetworkStatusApp;
