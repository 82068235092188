import React from "react";

export default function HomeTitle(props) {
  return (
    <div className="flex flex-col gap-2 py-4">
      <h2 className="text-4xl md:text-6xl font-thin text-gray-800 mb-0">
        Hello {props.userName}!
      </h2>
      <h3 className="text-lg md:text-2xl font-thin text-gray-400">
        {props.greeting}
      </h3>
    </div>
  );
}
