import React from "react";
import { Tag } from "antd";

const ServiceStatusTag = (props) => {
  let tag;
  switch (props.serviceStatus) {
    case "Active":
      tag = <Tag color="#87d068">{props.serviceStatus.toUpperCase()}</Tag>;
      break;
    case "Suspended":
      tag = <Tag color="#f50">{props.serviceStatus.toUpperCase()}</Tag>;
      break;
    case "Pending":
      tag = <Tag color="#2db7f5">{props.serviceStatus.toUpperCase()}</Tag>;
      break;
    default:
      tag = <Tag color="#D1D5DB">NO STATUS</Tag>;
      break;
  }
  return tag;
};
export default ServiceStatusTag;
