import React from "react";

const ClientName = ({ name }) => {
  return name.companyname ? (
    <span className="font-bold">{name.companyname}</span>
  ) : (
    <span>
      {name.firstname} {name.lastname}
    </span>
  );
};

export default ClientName;
