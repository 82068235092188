import { ExternalLinkIcon } from "@heroicons/react/outline";

const Title = (props) => {
  // console.log("Title props", props);
  return (
    <div className="flex p-2 justify-between">
      <div className="flex flex-col justify-between">
        <h4 className="p-0 m-0 font-normal uppercase">{props.title}</h4>
        <h5 className="p-0 m-0 font-light text-xs">{props.subtitle}</h5>
      </div>
      <div
        className={`flex gap-2 uppercase ${
          props.status == "Operational"
            ? "text-green-500"
            : props.status == "In Review" || props.status == "Partial"
            ? "text-yellow-500"
            : "text-red-500"
        }`}
      >
        <ExternalLinkIcon className="h-5 w-5" />
      </div>
    </div>
  );
};
export default Title;
