import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Keycloak from "keycloak-js";

import Header from "./components/UI/Header";
import Home from "./components/Home";

import BillingApp from "./components/Billing/BillingApp";
import Jobs from "./components/Jobs";
import NetworkStatusApp from "./components/NetworkStatus/NetworkStatusApp";
import cxLogo from "../src/assets/svg/cx-iso-color.svg";

export default function App() {
  let [keycloak, setKeycloak] = useState(null);
  let [authenticated, setAuthenthicated] = useState(false);

  useEffect(() => {
    const keycloak = Keycloak("/keycloak.json");
    keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
      setKeycloak(keycloak);
      setAuthenthicated(authenticated);
      // console.log("Keycloack object:", keycloak);
      // console.log("User authenthicated?", authenticated);
      // console.log("Keycloak token:", keycloakToken);
    });
  }, []);

  return authenticated ? (
    <Router>
      <Header keycloak={keycloak} />
      <Switch>
        <Route exact path="/">
          <Home keycloak={keycloak} />
        </Route>
        <Route path="/billing">
          <BillingApp keycloak={keycloak} />
        </Route>
        <Route path="/jobs">
          <Jobs keycloak={keycloak} />
        </Route>
        <Route path="/network-status" component={NetworkStatusApp} />
      </Switch>
    </Router>
  ) : (
    <div className="flex flex-col gap-8 h-screen w-screen items-center justify-center opacity-50">
      <img
        className="animate-spin flex h-16 w-16"
        src={cxLogo}
        alt="Conexum Logo"
      />
      <div className="font-thin">Processing</div>
    </div>
  );
}
