import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function CreateSectionForm({ closeModal, addSectionHandler }) {
  const schema = yup
    .object({
      name: yup.string().required(),
      description: yup.string().required(),
    })
    .required();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });
  return (
    <form
      onSubmit={handleSubmit((data) => {
        addSectionHandler(data);
        closeModal();
      })}
      className="flex flex-col flex-grow gap-4 text-lg font-thin"
    >
      <div className="flex flex-col justify-between">
        <div>
          <label
            htmlFor="name"
            className="block text-sm pb-2 font-medium text-gray-700"
          >
            Section name:
          </label>
          <div className="mt-1">
            <input
              {...register("name")}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="Description"
            className="block text-sm py-2 font-medium text-gray-700"
          >
            Section description:
          </label>
          <div className="mt-1">
            <textarea
              rows={6}
              {...register("description")}
              className="fappearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        type="submit"
        className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-200 bg-blue-600 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      >
        Submit
      </button>
    </form>
  );
}
